<template>
  <div class="col-12">
    <div class="card">
        <div class="card-header">
            الشركاء
        </div>
        <div class="card-body">
            <div class="col-12 table-responsive">
                <table class="table-hover table-bordered table table-sm">
                    <thead>
                        <th>
                            الاسم
                        </th>
                        <th>
                            الجوال
                        </th>
                        <th>
                            تم التفعيل
                        </th>
                        <th>
                            تاريخ الانضمام
                        </th>
                        <th>
                            الرصيد القابل للسحب
                        </th>
                        <th>
                            الرصيد المسحوب
                        </th>
                        <th>
                            كامل
                        </th>
                        <th>
                            خيارات
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="r in resellers" :key="r._id">
                            <td>
                                {{ r.name }}
                            </td>
                            <td>
                                {{ r.phone }}
                            </td>
                            <td>
                                <span v-if="r.activated" class="text-success">
                                    <i class="fa fa-check"></i> تم التفعيل
                                </span>
                                <span v-if="!r.activated">{{ r.opt }}</span>
                            </td>
                            <td>
                                {{ r.joindate }}
                            </td>
                            <td>
                                {{ r.withdrawal.toFixed(0) }}
                            </td>
                            <td>
                                {{ r.withdrawed.toFixed(0) }}
                            </td>
                            <td>
                                <span v-if="r.full" class="text-success">
                                    <i class="fa fa-check"></i> نعم
                                </span>
                                <span v-if="!r.full" class="text-danger">
                                    <i class="fa fa-times"></i> لا
                                </span>
                            </td>
                            <td>
                                <button class="btn btn-sm btn-primary" style="border-radius: 0px" @click="current = r;" v-b-modal.details>
                                    <i class="fa fa-cogs"></i>
                                    خيارات
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <b-modal id="details" title="التفاصيل" hide-footer>
        <div v-if="current._id">
            <div class="row">
                <div class="col-12 g">
                    <button class="btn btn-block btn-success" @click="getWithdrawalOrders()" v-b-modal.withdraw>
                        <i class="fa fa-usd"></i>
                        سحب الرصيد
                    </button>
                    <hr>
                    <button class="btn btn-block btn-danger" v-b-modal.insert @click="getInserts()">
                        <i class="fa fa-handshake-o"></i>
                        العمولات الخارجية
                    </button>
                    <hr>
                    كلمة المرور: {{ current.password }}
                    <hr>
                    <div class="form-group">
                      <label for="">دفع الكتروني على صدى</label>
                      <select class="form-control" v-model="current.online_pay">
                        <option :value="true">نعم</option>
                        <option :value="false">لا</option>
                      </select>
                    </div>
                    <div class="form-group" v-if="!current.online_pay">
                      <label for="">دفع يدوي على صدى</label>
                      <select class="form-control" v-model="current.manual_sada">
                        <option :value="true">نعم</option>
                        <option :value="false">لا</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="">كامل الخيارات</label>
                      <select class="form-control" v-model="current.full">
                        <option :value="true">نعم</option>
                        <option :value="false">لا</option>
                      </select>
                    </div>
                    <div class="form-group" v-if="!current.manual_sada && !current.online_pay">
                      <label for="">بيانات التحويل الخاصة بهذا الموزع</label>
                      <textarea class="form-control" v-model="current.manual_info" rows="3"></textarea>
                    </div>
                    <div class="form-group">
                      <label for="">النسبة</label>
                      <input type="number"
                        class="form-control" v-model="current.percent">
                    </div>
                    <div class="col-12 text-center g">
                        <button class="btn btn-sm btn-primary" @click="edit()">
                            حفظ التعديلات
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
    <b-modal id="insert" title="اعطاء مبلغ" hide-footer>
        <div class="form-group">
          <label for="">المبلغ</label>
          <input type="number"
            class="form-control" v-model="insert.amount">
        </div>
        <div class="form-group">
          <label for="">السبب/التفاصيل</label>
          <input type="text"
            class="form-control" v-model="insert.details" placeholder="اكتب هنا...">
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="insertNow()">
                اعطاء المبلغ
            </button>
        </div>
        <hr>
        العمليات السابقة
        <table class="table table-bordered table-sm">
            <thead>
                <th>
                    المبلغ
                </th>
                <th>
                    التاريخ
                </th>
                <th>
                    تمت المحاسبة
                </th>
                <th>
                    التفاصيل
                </th>
            </thead>
            <tbody>
                <tr v-for="i in inserts" :key="i._id">
                    <td>
                        {{ i.amount }}
                    </td>
                    <td>
                        {{ i.date.split("T")[0] }}
                    </td>
                    <td>
                        <span v-if="i.done">
                            نعم
                        </span>
                        <button class="btn btn-sm btn-danger" v-if="!i.done" @click="insertdone(i._id)">تعيين كـ تم</button>
                    </td>
                    <td>
                        {{ i.details }}
                    </td>
                </tr>
            </tbody>
        </table>
    </b-modal>
    <b-modal id="withdraw" title="سحب" hide-footer size="lg">
        <div v-if="withdraw_orders.length">
            <div class="col-12 text-center g">
                <div class="form-group">
                  <label for="">ملاحظاتك / تفاصيل التحويل</label>
                  <textarea class="form-control" v-model="notes" rows="3"></textarea>
                </div>
                <button class="btn btn-success" @click="withdraw()">
                    <i class="fa fa-check"></i>
                    تم السحب ({{ total() }} ريال)
                </button>
            </div>
            <div class="col-12 table-responsive">
                <table class="table table-sm table-bordered table-hover">
                    <thead>
                        <th>
                            اختيار
                        </th>
                        <th>
                            الخدمة
                        </th>
                        <th>
                            النسبة
                        </th>
                        <th>
                            التاريخ
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="order in withdraw_orders" :key="order._id">
                            <td>
                                <div class="form-check">
                                  <label class="form-check-label">
                                    <input type="checkbox" class="form-check-input" v-model="selected" :value="order.order_id"> #{{ order.order_id }}
                                  </label>
                                </div>
                            </td>
                            <td>
                                {{ order.service }}
                            </td>
                            <td>
                                {{ (order.original * order.reseller_percent).toFixed(1) }}
                            </td>
                            <td>
                                {{ order.date }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            resellers: [],
            current: {},
            insert: {amount: 0, details: ""},
            withdraw_orders: [],
            selected: [],
            notes: "",
            inserts: []
        }
    },
    created(){
        var g = this;
        g.getResellers();
    },
    methods: {
        total(){
            var n = 0, g = this;
            this.selected.forEach(function(b){
                g.withdraw_orders.forEach(function(a){
                    if(b == a.order_id){
                        n = n + new Number((a.original * a.reseller_percent).toFixed(1));
                    }
                })
            })
            return n.toFixed(1);
        },
        getInserts(){
            var g = this;
            $.post(api + '/admin/resellers/inserts', {
                jwt: localStorage.getItem("jwt"),
                id: g.current._id
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    g.inserts = r.response
                }
            }).fail(function(){
            
            })
        },
        insertNow(){
            var g = this;
            $.post(api + '/admin/resellers/insert', {
                jwt: localStorage.getItem("jwt"),
                id: g.current._id,
                amount: g.insert.amount,
                details: g.insert.details
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    alert("تم بنجاح", 100)
                    g.getInserts()
                }
            }).fail(function(){
            
            })
        },
        insertdone(id){
            if(confirm("متأكد؟")){
                var g = this;
                $.post(api + '/admin/resellers/inserts/done', {
                    jwt: localStorage.getItem("jwt"),
                    id: id
                }).then(function(r){
                    g.loading = false
                    if(r.status != 100){
                        alert(r.response, 200)
                    }else{
                        alert("تم بنجاح", 100)
                        g.getInserts()
                    }
                }).fail(function(){
                
                })
            }
        },
        getWithdrawalOrders(){
            var g = this;
            $.post(api + '/admin/resellers/reseller-withdrawal-orders', {
                jwt: localStorage.getItem("jwt"),
                code: this.current.code
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.withdraw_orders = r.response
                    g.selected = r.response.map(function(x){
                        return x.order_id;
                    })
                }
            }).fail(function(){
            
            })
        },
        getResellers(){
            var g = this;
            $.post(api + '/admin/resellers/list', {
                jwt: localStorage.getItem("jwt"),
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.resellers = r.response
                }
            }).fail(function(){
            
            })
        },
        edit(){
            var g = this;
            $.post(api + '/admin/resellers/edit', {
                jwt: localStorage.getItem("jwt"),
                code: g.current.code,
                percent: g.current.percent,
                manual_info: g.current.manual_info,
                manual_sada: g.current.manual_sada,
                full: g.current.full,
                online_pay: g.current.online_pay
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    alert("تم الحفظ", 100)
                }
            }).fail(function(){
            
            })
        },
        withdraw(){
            if(confirm("متاكد من السحب؟")){
                var g = this;
                $.post(api + '/admin/resellers/withdraw', {
                    jwt: localStorage.getItem("jwt"),
                    code: g.current.code,
                    selected: g.selected,
                    notes: g.notes,
                    total: g.total()
                }).then(function(r){
                    g.loading = false
                    if(r.status != 100){
                        alert(r.response)
                    }else{
                        alert("تم السحب بنجاح", 100);
                        g.getResellers()
                        $("#withdraw___BV_modal_header_ > button").click()
                    }
                }).fail(function(){
                
                })
            }
        }
    }
}
</script>

<style>

</style>